import BusyIndicator from "@components/busyIndicator";
import { Separator } from "@components/separator/Separator";
import React from "react";

import Dashboard from "../../components/dashboard";
import { AppContext } from "../../contexts/appContext/AppContext.types";
import { IDashboardManager } from "./DashboardManager";
import { HomeDashboardHeaderStyled } from "./Home.style";

interface IProps {
    manager: IDashboardManager;
    inEditMode: boolean;
    showHeader?: boolean;
}

export default class HomeDashboard extends React.Component<IProps> {
    static contextType = AppContext;

    _unsubscribe: () => void;

    constructor(props: IProps) {
        super(props);

        this._unsubscribe = props.manager?.subscribe(this);
    }

    componentWillUnmount() {
        this._unsubscribe?.();
    }

    render() {
        const { definition, config, updateLayout, companyId } = this.props.manager;
        const isBusy = companyId !== this.context.getCompanyId();

        return (
            <>
                {this.props.showHeader && (
                    <HomeDashboardHeaderStyled>
                        {definition.title}
                        <Separator isBold/>
                    </HomeDashboardHeaderStyled>
                )}
                {isBusy ? <BusyIndicator isDelayed />
                        : <Dashboard definition={definition}
                               config={config}
                               onLayoutConfigChange={updateLayout}
                               inEditMode={this.props.inEditMode}/>}
            </>
        );
    }
}
